import React, { useEffect, useRef, useState, useContext } from "react";
import Link from 'next/link'
import { useQuery, useManualQuery, useMutation } from 'graphql-hooks'
import { Store } from '../../store'
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { removeCookies } from './Utils'
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

export const UserMenu = () => {
  const { state, dispatch } = useContext(Store)
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const handleLogout = () => {
    removeCookies()

    router.push('/').then(() => window.location.reload())
  }

  const userImage = (user) => {
    if(user?.__typename === 'Subscriber') {
      return (
        <div className="rounded-full w-8 h-8 my-auto bg-gray-600 text-white text-xl hover:bg-gray-400 cursor-pointer flex justify-center items-center">
          {user.email?.charAt(0)?.toUpperCase()}
        </div>
      )
    } else {
      return(
        <img src={user?.imageUrl}
          className='rounded-full w-8 h-8 object-cover my-auto bg-white hover:bg-gray-200 cursor-pointer'
          alt='user'
        />
      )
    }
  }

  return (
    <Menu placement="bottom-end">
      {state.currentUser ? (
        <MenuHandler>
          <div className="mr-2 md:mr-6 w-10 shrink-0 rounded-full p-1">
            {userImage(state.currentUser)}
          </div>
        </MenuHandler>
      ) : (
        <></>
      )}

      <MenuList>
        <MenuItem>
          <div className="flex items-center">
            <div className="mr-2">{userImage(state.currentUser)}</div>
            <div className="">
              <div className="font-bold text-base">
                {state.currentUser?.displayName}
              </div>
              <div className="text-gray-500">{state.currentUser?.email}</div>
            </div>
          </div>
        </MenuItem>
        <hr className="my-4" />
        <MenuItem>
          <a href={`${process.env.NEXT_PUBLIC_FRONT_URL}dashboard`}>
            <div className="flex items-center hover:bg-gray-100 cursor-pointer ">
              <img
                src="/images/letter.svg"
                className="ml-3 mr-2 w-5 h-5"
                alt="newsletter"
              />
              <div className="p-2">ニュースレター</div>
            </div>
          </a>
        </MenuItem>

        <MenuItem>
          <a href={`${process.env.NEXT_PUBLIC_FRONT_URL}dashboard/payments`}>
            <div className="flex items-center hover:bg-gray-100 cursor-pointer ">
              <img
                src="/images/list.svg"
                className="ml-3 mr-2 w-5 h-5"
                alt="list"
              />
              <div className="p-2 ">購入履歴</div>
            </div>
          </a>
        </MenuItem>

        <MenuItem>
          <a href={`${process.env.NEXT_PUBLIC_FRONT_URL}dashboard/setting`}>
            <div className="flex items-center hover:bg-gray-100 cursor-pointer ">
              <img
                src="/images/setting.svg"
                className="ml-3 mr-2 w-5 h-5"
                alt="setting"
              />
              <div className="p-2 rounded-xl ">設定</div>
            </div>
          </a>
        </MenuItem>
        <hr className="my-4" />
        <MenuItem>
          <a href={process.env.NEXT_PUBLIC_FRONT_URL + "workspace"}>
            { state?.currentUser?.__typename === 'Subscriber' ? (
            <div className="flex items-center rounded-xl bg-gray-100 cursor-pointer ">
              <img
                src="/images/wifi.svg"
                className="ml-3 mr-2 w-5 h-5"
                alt="workspace"
              />
              <div className="p-2 rounded-xl">ニュースレターを<br />配信してみる</div>
            </div>
            ) :(
            <div className="flex items-center hover:bg-gray-100 cursor-pointer ">
              <img
                src="/images/wifi.svg"
                className="ml-3 mr-2 w-5 h-5"
                alt="workspace"
              />
              <div className="p-2 rounded-xl">配信ワークスペース</div>
            </div>
            )}
          </a>
        </MenuItem>
        <hr className="my-4" />

        <MenuItem>
          <div
            className="hover:bg-gray-100 cursor-pointer flex items-center"
            onMouseDown={(e) => {
              handleLogout();
            }}
          >
            <img
              src="/images/logout.svg"
              className="ml-3 mr-2 w-5 h-5"
              alt="logout"
            />
            <div className="p-2 rounded-xl hover:bg-gray-100 cursor-pointer">
              ログアウト
            </div>
          </div>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default UserMenu;
